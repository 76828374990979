import * as React from "react"
import { useState } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"

import Layout from "../../Layout/layout"
import UrlsListFilterAndSearch from "./urlsListFilterAndSearch"
import AddUrlModal from "./addUrlModal"

import "../../../css/jquery.dataTables.min.css"
import "./urls.css"

const UrlsList = props => {
  const [addUrlShow, setAddUrlShow] = useState(false),
    [showTrashButton, setShowTrashButton] = useState(false),
    [itemsToDelete, setItemsToDelete] = useState([]),
    handleAddUrlShow = () => setAddUrlShow(true)

  const handleDelete = event => {
    if (event.target.checked) {
      itemsToDelete.push(event.target.value)
    } else {
      const index = itemsToDelete.indexOf(event.target.value)
      itemsToDelete.splice(index, 1)
    }
    setItemsToDelete(itemsToDelete)
    setShowTrashButton(itemsToDelete.length > 0)
  }

  const filterByMetricContainer = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Filter By Metric</Popover.Header>
      <Popover.Body>
        <div id="filterByMetricContainer">
          <select id="filterByMetric" defaultValue="ClickThrough">
            <option value="">== All ==</option>
            <option value="Doc" data-icon="fa-edit">
              eSign / Complete a Doc / Form
            </option>
            <option value="Product" data-icon="fa-shopping-cart">
              Make a Payment (Buy a Product)
            </option>
            <option value="Scheduler" data-icon="fa-calendar-plus">
              Schedule an Appointment
            </option>
            <option value="Chatbot" data-icon="fa-comments">
              Chat with my Bot
            </option>
            <option value="Review" data-icon="fa-star">
              Review (Complete Review Form)
            </option>
            <option value="ClickThrough" data-icon="fa-sign-out">
              Click Through / View Content
            </option>
          </select>
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <Layout>
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col">
            <div className="row tabularMenuContainer align-items-center">
              <div className="col">
                <UrlsListFilterAndSearch />
              </div>
              <div className="col-sm-8">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                    <li className="nav-item">
                      <button type="button" className="btn btn-primary" onClick={handleAddUrlShow}>
                        <i className="bi bi-plus-lg" />
                        <span>Add New</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <table id="tblUrls" className="table dataTable" cellSpacing="0" width="100%">
                  <thead>
                    <tr>
                      <th width="5%">&nbsp;</th>
                      <th width="60%">Name / Title</th>
                      <th width="15%" className="d-none d-md-table-cell">
                        <OverlayTrigger
                          type="button"
                          trigger="click"
                          placement="right"
                          overlay={filterByMetricContainer}
                        >
                          <button type="button" className="btn btn-sm btnTHTypeFilter" id="btnTHTypeFilter">
                            <i className="bi bi-funnel-fill" />
                          </button>
                        </OverlayTrigger>
                      </th>
                      <th width="20%" className="text-end d-none d-lg-table-cell" />
                    </tr>
                    <tr
                      className={showTrashButton ? "table_action_container" : "table_action_container d-none"}
                    >
                      <td colSpan="4" className="text-left">
                        <div className="batch-action-container">
                          <button className="btn btn-trash">
                            <i className="bi bi-trash-fill" /> Move to Trash
                          </button>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={4} className="text-center tableColChkDel">
                        No Custom URLs added yet. Want to{" "}
                        <a style={{ color: "#125fad", cursor: "pointer" }} onClick={handleAddUrlShow}>
                          click here to add one
                        </a>{" "}
                        now?
                      </td>
                      {/* <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                          <input
                            type="checkbox"
                            name="fileid[]"
                            id="C_ID5"
                            className="css-checkbox"
                            value="1"
                            onChange={handleDelete}
                          />
                          <label htmlFor="C_ID5" className="css-checkbox-label" />
                          <span className="tableColChkSpan btn-secondary">
                            <i className="bi bi-link-45deg" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href="#">Clickthrough to Mysite.com by Chiropractors via Email on 9 Jan</a>
                      </td>
                      <td className="d-none d-md-table-cell">
                        0 Completion Yet{" "}
                        <a href="#">
                          <i className="bi bi-pencil-fill" />
                        </a>
                      </td>
                      <td className="text-end tableColAction d-none d-lg-table-cell">
                        <a
                          href="#"
                          className="btn btnOpenChatDock btnURLChatDock"
                          data-bs-toggle="tooltip"
                          data-bs-title="History"
                        >
                          <i className="bi bi-arrow-counterclockwise" />
                        </a>
                        <a href="#" className="btn btn-edit" data-bs-toggle="tooltip" data-bs-title="Edit">
                          <i className="bi bi-pencil-fill" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-delete"
                          data-bs-toggle="tooltip"
                          data-bs-title="Delete"
                        >
                          <i className="bi bi-trash-fill" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                          <input
                            type="checkbox"
                            name="fileid[]"
                            id="C_ID4"
                            className="css-checkbox"
                            value="2"
                            onChange={handleDelete}
                          />
                          <label htmlFor="C_ID4" className="css-checkbox-label" />
                          <span className="tableColChkSpan btn-secondary">
                            <i className="bi bi-link-45deg" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a className="activeCampaign" href="campaign_details.html">
                          Chiro Funding Marketing
                        </a>{" "}
                        <a className="ms-2" href="campaign_details.html?#team_panel">
                          <i className="bi bi-people-fill" /> Chiropractors (3691)
                        </a>{" "}
                        via <i className="bi bi-three-dots" /> ChiroFunding Sequence
                      </td>
                      <td className="d-none d-md-table-cell">
                        0 Completion Yet{" "}
                        <a href="#">
                          <i className="bi bi-pencil-fill" />
                        </a>
                      </td>
                      <td className="text-end tableColAction d-none d-lg-table-cell">
                        <a
                          href="#"
                          className="btn btnOpenChatDock btnURLChatDock"
                          data-bs-toggle="tooltip"
                          data-bs-title="History"
                        >
                          <i className="bi bi-arrow-counterclockwise" />
                        </a>
                        <a href="#" className="btn btn-edit" data-bs-toggle="tooltip" data-bs-title="Edit">
                          <i className="bi bi-pencil-fill" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-delete"
                          data-bs-toggle="tooltip"
                          data-bs-title="Delete"
                        >
                          <i className="bi bi-trash-fill" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                          <input
                            type="checkbox"
                            name="fileid[]"
                            id="C_ID1"
                            className="css-checkbox"
                            value="3"
                            onChange={handleDelete}
                          />
                          <label htmlFor="C_ID1" className="css-checkbox-label" />
                          <span className="tableColChkSpan btn-secondary">
                            <i className="bi bi-link-45deg" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href="#">click.Swiftcrm.com/{"{username}"}/abc</a>
                      </td>
                      <td className="d-none d-md-table-cell">
                        0 Completion Yet{" "}
                        <a href="#">
                          <i className="bi bi-pencil-fill" />
                        </a>
                      </td>
                      <td className="text-end tableColAction d-none d-lg-table-cell">
                        <a
                          href="#"
                          className="btn btnOpenChatDock btnURLChatDock"
                          data-bs-toggle="tooltip"
                          data-bs-title="History"
                        >
                          <i className="bi bi-arrow-counterclockwise" />
                        </a>
                        <a href="#" className="btn btn-edit" data-bs-toggle="tooltip" data-bs-title="Edit">
                          <i className="bi bi-pencil-fill" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-delete"
                          data-bs-toggle="tooltip"
                          data-bs-title="Delete"
                        >
                          <i className="bi bi-trash-fill" />
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                          <input
                            type="checkbox"
                            name="fileid[]"
                            id="C_ID2"
                            className="css-checkbox"
                            value="4"
                            onChange={handleDelete}
                          />
                          <label htmlFor="C_ID2" className="css-checkbox-label" />
                          <span className="tableColChkSpan btn-secondary">
                            <i className="bi bi-link-45deg" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href="#">click.Swiftcrm.com/{"{username}"}/xyz</a>
                        <div className="SwiftCloudTableTags ms-2">
                          <ul>
                            <li>
                              <a href="#">Tag1</a>
                            </li>
                            <li>
                              <a href="#">Tag2</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td className="d-none d-md-table-cell">
                        0 Completion Yet{" "}
                        <a href="#">
                          <i className="bi bi-pencil-fill" />
                        </a>
                      </td>
                      <td className="text-end tableColAction d-none d-lg-table-cell">
                        <a
                          href="#"
                          className="btn btnOpenChatDock btnURLChatDock"
                          data-bs-toggle="tooltip"
                          data-bs-title="History"
                        >
                          <i className="bi bi-arrow-counterclockwise" />
                        </a>
                        <a href="#" className="btn btn-edit" data-bs-toggle="tooltip" data-bs-title="Edit">
                          <i className="bi bi-pencil-fill" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-delete"
                          data-bs-toggle="tooltip"
                          data-bs-title="Delete"
                        >
                          <i className="bi bi-trash-fill" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                          <input
                            type="checkbox"
                            name="fileid[]"
                            id="C_ID3"
                            className="css-checkbox"
                            value="5"
                            onChange={handleDelete}
                          />
                          <label htmlFor="C_ID3" className="css-checkbox-label" />
                          <span className="tableColChkSpan btn-secondary">
                            <i className="bi bi-link-45deg" />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href="campaign_details.html">{"{CAMPAIGNTITLEHERE}"}</a>{" "}
                        <a className="ms-2" href="campaign_details.html?#team_panel">
                          <i className="bi bi-people-fill" /> {"{AUDIENCEGROUP}"}
                        </a>{" "}
                        via {"{CHANNELMETHOD}"}
                      </td>
                      <td className="d-none d-md-table-cell">
                        <i className="bi bi-box-arrow-right" /> 12 Clicks{" "}
                        <a href="#">
                          <i className="bi bi-pencil-fill" />
                        </a>
                      </td>
                      <td className="text-end tableColAction d-none d-lg-table-cell">
                        <a
                          href="#"
                          className="btn btnOpenChatDock btnURLChatDock"
                          data-bs-toggle="tooltip"
                          data-bs-title="History"
                        >
                          <i className="bi bi-arrow-counterclockwise" />
                        </a>
                        <a href="#" className="btn btn-edit" data-bs-toggle="tooltip" data-bs-title="Edit">
                          <i className="bi bi-pencil-fill" />
                        </a>
                        <a
                          href="#"
                          className="btn btn-delete"
                          data-bs-toggle="tooltip"
                          data-bs-title="Delete"
                        >
                          <i className="bi bi-trash-fill" />
                        </a>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddUrlModal addUrlShow={addUrlShow} setAddUrlShow={setAddUrlShow} />
    </Layout>
  )
}

export default UrlsList
