import * as React from "react"
import Modal from "react-bootstrap/Modal"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import "../../Layout/global_url_injector.css"
import { StaticImage } from "gatsby-plugin-image"

const AddUrlModal = ({ addUrlShow, setAddUrlShow }) => {
  const handleClose = () => {
    setAddUrlShow(false)
  }

  return (
    <Modal show={addUrlShow} onHide={handleClose} size="lg">
      <div id="Global_URLInjector" tabIndex="-1" role="dialog" aria-hidden="true">
        <Modal.Header>
          <h2 className="mb-0">
            <i className="bi bi-link-45deg" /> Add URL
          </h2>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="row g-0">
            <div className="col-sm-12 mb-3">
              <div className="pageTitle_Editor">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name / Title"
                  defaultValue="Clickthrough to Mysite.com by Chiropractors via Email on 9 Jan"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="panel panel-default" id="URLInj_pinkBoxContainer">
                <div className="panel-heading">
                  <h3 className="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      href="#URLInj_pinkBox"
                      role="button"
                      aria-expanded="true"
                      aria-controls="URLInj_pinkBox"
                    >
                      <i className="bi bi-box-arrow-in-right" />
                      FOR THEM: User will...{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Destination(s) / Input Target URL(s)</Tooltip>}
                      >
                        <i className="bi bi-question-circle-fill" />
                      </OverlayTrigger>
                    </a>
                  </h3>
                </div>
                <div id="URLInj_pinkBox" className="panel-collapse collapse show">
                  <div className="panel-body py-3">
                    <div className="">
                      <div className="row align-items-center mb-2">
                        <div className="col-lg-12 hiddenSelectboxContainer">
                          <span className="text-dash-underline userWillGoText">in all cases</span>
                          <select
                            className="form-select inlineSelectBox display-none userWillGo"
                            defaultValue=""
                          >
                            <option value="in all cases">in all cases</option>
                            <option value="splitTested50">be Split Tested (50-50) &</option>
                            <option value="splitTested33">be Split Tested (33%) &</option>
                            <option value="RAVEScore">depending on RAVE score</option>
                            <option value="TagScore">depending on TagScore</option>
                            <option value="Agenda">depending on Agenda</option>
                            <option value="PayWall">depending on PayWall</option>
                          </select>
                          be sent to...
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 userWillGoOpts inAllCaseBox">
                          <div className="pinkBoxBorder">
                            <div className="row align-items-center">
                              <div className="col-lg-12">
                                <div className="input-group">
                                  <select className="form-select urlTypeList" defaultValue="">
                                    <option value="URL">URL</option>
                                  </select>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="URLInj_anyurl"
                                    placeholder="https://"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 mt-2 splitTested50 splitTested33 userWillGoOpts display-none">
                                <div className="input-group">
                                  <select className="form-select urlTypeList" defaultValue="">
                                    <option value="URL">URL</option>
                                  </select>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="URLInj_anyurl"
                                    placeholder="https://"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 mt-2 splitTested33 userWillGoOpts display-none">
                                <div className="input-group">
                                  <select className="form-select urlTypeList" defaultValue="">
                                    <option value="URL">URL</option>
                                  </select>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="URLInj_anyurl"
                                    placeholder="https://"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 userWillGoOpts raveScoreBox display-none">
                          <div className="pinkBoxBorder mb-3">
                            <div className="row row-cols-lg-auto g-2 align-items-center mb-2">
                              <div className="col-12">If Score is...</div>
                              <div className="col-12">
                                <select
                                  name="tagScoreOpt"
                                  className="form-select mb-1 mb-lg-0"
                                  defaultValue=""
                                >
                                  <option value="lessthan">&#60; Less Than</option>
                                  <option value="morethan">> More Than</option>
                                </select>
                              </div>
                              <div className="col-12">
                                <input
                                  type="text"
                                  name="tagScoreInput"
                                  className="form-control tagScoreNo"
                                  defaultValue="50"
                                />
                              </div>
                            </div>
                            <div className="row align-items-center">
                              <div className="col-lg-12">
                                <div className="input-group">
                                  <div className="input-group-text">go to...</div>
                                  <select className="form-select urlTypeList" defaultValue="">
                                    <option value="URL">URL</option>
                                  </select>
                                  <input type="text" className="form-control" placeholder="https://" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-lg-12 text-center mb-3">
                              <a href="#" className="btnAddNewGreenDashed btnAddNewPinkRaveScoreBox">
                                <i className="bi bi-plus-lg" />
                              </a>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-group">
                                <div className="input-group-text">Otherwise go to...</div>
                                <select className="form-select urlTypeList" defaultValue="">
                                  <option value="URL">URL</option>
                                </select>
                                <input type="text" className="form-control" placeholder="https://" />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <small>Rules will run top to bottom.</small>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 userWillGoOpts tagScoreBox display-none">
                          <div className="pinkBoxBorder mb-3">
                            <div className="row row-cols-lg-auto g-2 align-items-center mb-2">
                              <div className="col-12">If</div>
                              <div className="col-12 tagsInputContainer">
                                <input
                                  type="text"
                                  className="form-control tagScoreInput"
                                  defaultValue="EditMe"
                                />
                              </div>
                              <div className="col-12">Score is...</div>
                              <div className="col-12">
                                <select
                                  name="tagScoreOpt"
                                  className="form-select mb-1 mb-lg-0"
                                  defaultValue=""
                                >
                                  <option value="lessthan">&#60; Less Than</option>
                                  <option value="morethan">> More Than</option>
                                </select>
                              </div>
                              <div className="col-12">
                                <input
                                  type="text"
                                  name="tagScoreInput"
                                  className="form-control tagScoreNo"
                                  defaultValue="50"
                                />
                              </div>
                            </div>
                            <div className="row align-items-center">
                              <div className="col-lg-12">
                                <div className="input-group">
                                  <div className="input-group-text">go to...</div>
                                  <select className="form-select urlTypeList" defaultValue="">
                                    <option value="URL">URL</option>
                                  </select>
                                  <input type="text" className="form-control" placeholder="https://" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-lg-12 text-center mb-3">
                              <a href="#" className="btnAddNewGreenDashed btnAddNewPinkTagScoreBox">
                                <i className="bi bi-plus-lg" />
                              </a>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-group">
                                <div className="input-group-text">Otherwise go to...</div>
                                <select className="form-select urlTypeList" defaultValue="">
                                  <option value="URL">URL</option>
                                </select>
                                <input type="text" className="form-control" placeholder="https://" />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <small>Rules will run top to bottom.</small>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 userWillGoOpts agendaBox display-none">
                          <div className="pinkBoxBorder mb-3">
                            <div className="row row-cols-lg-auto g-2 align-items-center mb-2">
                              <div className="col-12">If Agenda is...</div>
                            </div>
                            <div className="row align-items-center">
                              <div className="col-lg-12">
                                <div className="input-group">
                                  <select className="form-select agendaList" defaultValue="">
                                    <option value="capture">Capture</option>
                                    <option value="build_trust">Build Trust</option>
                                    <option value="tripwire_offer">Tripwire Offer</option>
                                    <option value="intro_offer">Intro Offer</option>
                                    <option value="main_offer">Main Offer</option>
                                    <option value="upsell_offer">Upsell Offer</option>
                                    <option value="promoter">Promoter</option>
                                  </select>
                                  <div className="input-group-text">go to...</div>
                                  <select className="form-select urlTypeList" defaultValue="">
                                    <option value="URL">URL</option>
                                  </select>
                                  <input type="text" className="form-control" placeholder="https://" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-lg-12 text-center mb-3">
                              <a href="#" className="btnAddNewGreenDashed btnAddNewPinkAgendaBox">
                                <i className="bi bi-plus-lg" />
                              </a>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-group">
                                <div className="input-group-text">Otherwise go to...</div>
                                <select className="form-select urlTypeList" defaultValue="">
                                  <option value="URL">URL</option>
                                </select>
                                <input type="text" className="form-control" placeholder="https://" />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <small>Rules will run top to bottom.</small>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 userWillGoOpts paywallBox display-none">
                          <div className="pinkBoxBorder mb-3">
                            <div className="row row-cols-lg-auto g-2 align-items-center mb-2">
                              <div className="col-12">If Paywall is...</div>
                            </div>
                            <div className="row align-items-center">
                              <div className="col-lg-12">
                                <div className="input-group">
                                  <select className="form-select agendaList" defaultValue="">
                                    <option value="Allowed">Allowed</option>
                                    <option value="Disallowed">Disallowed</option>
                                  </select>
                                  <div className="input-group-text">go to...</div>
                                  <select className="form-select urlTypeList" defaultValue="">
                                    <option value="URL">URL</option>
                                  </select>
                                  <input type="text" className="form-control" placeholder="https://" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-12">
                              <div className="input-group">
                                <div className="input-group-text">Otherwise go to...</div>
                                <select className="form-select urlTypeList" defaultValue="">
                                  <option value="URL">URL</option>
                                </select>
                                <input type="text" className="form-control" placeholder="https://" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 pt-3">
                          <div className="mb-3 row g-0 pinkBoxBorder">
                            <div className="col-sm-12">
                              <div className="form-check-inline mb-0 piggybackCheck">
                                <input
                                  type="checkbox"
                                  name="piggybackOpt"
                                  id="piggybackOpt"
                                  className="css-checkbox compressURLOn"
                                  defaultValue="piggyBackContent"
                                />
                                <label htmlFor="piggybackOpt" className="css-checkbox-label radGroup2 mb-0">
                                  <i className="bi bi-subtract" /> Add Piggyback Content
                                </label>
                              </div>
                            </div>
                            <div
                              className="col-lg-12 auto display-none py-3 customModalContainer"
                              id="addNewPiggybackFormContainer"
                            >
                              <div className="row g-0">
                                <div className="col-lg-12 colHeading">
                                  <strong>
                                    <i className="bi bi-plus-lg" /> ADD NEW <i className="bi bi-subtract" />{" "}
                                    PIGGYBACK
                                  </strong>
                                </div>
                                <div className="col-lg-12 dh-block-body">
                                  <form>
                                    <div className="mb-3 row align-items-center">
                                      <div className="col-sm-4">Style</div>
                                      <div className="col-sm-8">
                                        <select
                                          name="styleOpt"
                                          id="styleOpt"
                                          className="form-select"
                                          defaultValue=""
                                        >
                                          <option value="Chat">Chat</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                      <div className="col-sm-4">Headline / Offer</div>
                                      <div className="col-sm-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="ctaText"
                                          defaultValue="Free Video: 7 Tips to Triple Your Leads"
                                        />
                                      </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                      <div className="col-sm-4">Button</div>
                                      <div className="col-sm-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="ctaBtnText"
                                          defaultValue="Get My Copy"
                                        />
                                      </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                      <div className="col-sm-4">Button URL</div>
                                      <div className="col-sm-8">
                                        <input
                                          type="url"
                                          className="form-control"
                                          name="ctaBtnURl"
                                          defaultValue="https://"
                                        />
                                      </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                      <div className="col-sm-4">Button Text Color</div>
                                      <div className="col-sm-8">
                                        <div className="input-group colorpicker-component">
                                          <input
                                            type="text"
                                            defaultValue="#125fad"
                                            className="form-control"
                                          />
                                          <span className="input-group-text">
                                            <i />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                      <div className="col-sm-4">Button Background Color</div>
                                      <div className="col-sm-8">
                                        <div className="input-group colorpicker-component">
                                          <input
                                            type="text"
                                            defaultValue="#FFFFFF"
                                            className="form-control"
                                          />
                                          <span className="input-group-text">
                                            <i />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-3 row align-items-center">
                                      <div className="col-sm-4">Location</div>
                                      <div className="col-sm-8">
                                        <select className="form-select" defaultValue="">
                                          <option value="Bottom Right">Bottom Right</option>
                                          <option value="Bottom Left">Bottom Left</option>
                                          <option value="Top Right">Top Right</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-12 text-end">
                                        <button
                                          type="button"
                                          name="btnCancel"
                                          id="btnCancel"
                                          className="btn btn-default"
                                        >
                                          <i className="bi bi-x-lg" /> Cancel
                                        </button>
                                        <button
                                          type="button"
                                          name="btnPreview"
                                          id="btnPreview"
                                          className="btn btn-secondary"
                                        >
                                          <i className="bi bi-box-arrow-up-right" /> Preview / Test
                                        </button>
                                        <button
                                          type="button"
                                          name="btnSave"
                                          id="btnSave"
                                          className="btn btn-primary"
                                        >
                                          <i className="bi bi-check2" /> Save
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row g-0 pinkBoxBorder">
                            <div className="col-sm-12">
                              <div className="mb-0 chatBotCheck">
                                <input
                                  type="checkbox"
                                  name="addChatbackOpt"
                                  id="addChatbackOpt"
                                  className="css-checkbox compressURLOn"
                                  defaultValue="addChatbot"
                                />
                                <label htmlFor="addChatbackOpt" className="css-checkbox-label radGroup2 mb-0">
                                  <i className="bi bi-chat-fill" /> Add Chatbot
                                </label>
                              </div>
                              <div className="chatbotOptsShow mt-3 display-none">
                                <select
                                  name="chatBotOpts"
                                  id="chatBotOpts"
                                  className="form-select"
                                  defaultValue=""
                                >
                                  <option> == Chatbots ==</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel-default" id="URLInj_beigeContainer">
                <div className="panel-heading">
                  <h3 className="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      href="#URLInj_beige"
                      role="button"
                      aria-expanded="false"
                      aria-controls="URLInj_beige"
                    >
                      <i className="bi bi-megaphone-fill" />
                      Macro Tracking
                    </a>
                  </h3>
                </div>
                <div id="URLInj_beige" className="panel-collapse collapse">
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-lg-12 pt-3">
                        <div className="dataInner">
                          <div className="mb-3 row g-0 initialData">
                            <div className="col-sm-12 trackingMarketing" id="marketingTrackingPanel">
                              <div className="campaignOptions">
                                <div className="row utm_campaign_container">
                                  <div className="col-sm-12">
                                    <input
                                      type="checkbox"
                                      name="enableMacroTracking"
                                      id="enableMacroTracking"
                                      className="css-checkbox compressURLOn"
                                      defaultValue="1"
                                    />
                                    <label
                                      htmlFor="enableMacroTracking"
                                      className="css-checkbox-label radGroup2 mb-0"
                                    >
                                      Enable Macro Tracking
                                    </label>
                                  </div>
                                </div>

                                <div className="mt-3 row macro_tracking_container d-none">
                                  <div className="col-sm-12">
                                    <div className="mb-3 row utm_campaign_container">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            Used for group your spending so you can determine cost-per-lead or
                                            cost-per-sale and thus calculate return on investment.
                                          </Tooltip>
                                        }
                                      >
                                        <label htmlFor="utm_campaign" className="col-sm-2 col-form-label">
                                          Experiment / Campaign
                                        </label>
                                      </OverlayTrigger>
                                      <div className="col-sm-5">
                                        <select
                                          name="utm_campaign"
                                          id="utm_campaign"
                                          className="form-select"
                                          defaultValue=""
                                        >
                                          <option value="">== Select ==</option>
                                          <option value="AddNewCampaign">
                                            == ADD NEW / EDIT CAMPAIGNS ==
                                          </option>
                                        </select>
                                      </div>
                                      <div className="col-sm-5 utm_campaign_tooltip campaign_tooltip" />
                                    </div>

                                    <div className="mb-3 row">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            Required. Use <strong>utm_medium</strong> to identify a medium
                                            such as email or cost-per- click. <em>Example</em>: utm_medium=cpc
                                          </Tooltip>
                                        }
                                      >
                                        <label htmlFor="utm_source" className="col-sm-2 col-form-label">
                                          Method / Channel
                                        </label>
                                      </OverlayTrigger>
                                      <div className="col-sm-5">
                                        <select
                                          id="utm_source"
                                          name="utm_source"
                                          className="form-select"
                                          defaultValue=""
                                        >
                                          <option disabled value="-- ONLINE --" title="undefined">
                                            {" "}
                                            -- ONLINE --
                                          </option>
                                          <option value="email" title="Email">
                                            Email
                                          </option>
                                          <option value="retargeting" title="Retargeting">
                                            Retargeting / Remarketing
                                          </option>
                                          <option value="content" title="Inbound / Content">
                                            Content Bait / Inbound Marketing
                                          </option>
                                          <option value="cpc" title="SEM (Pay Per Click)">
                                            SEM Pay-Per-Click (PPC / CPC)
                                          </option>
                                          <option value="cpm" title="SEM (Display Media / Cost Per View)">
                                            SEM Display Media (CPM)
                                          </option>
                                          <option value="cpa" title="SEM (Pay Per Action)">
                                            Affiliates / Cost-Per-Action (CPA)
                                          </option>
                                          <option value="social_unpaid" title="Social (Unpaid)">
                                            Social (Unpaid Social Posts)
                                          </option>
                                          <option value="social_paid" title="Native Ads / Paid Social">
                                            Social (Paid) / Native Ads
                                          </option>
                                          <option value="pr" title="Press Releases / Public Relations">
                                            Press Releases / Public Relations
                                          </option>
                                          <option value="seo" title="SEO (Organic Unpaid)">
                                            Search Engine Optimization (SEO)
                                          </option>
                                          <option value="viral" title="Viral">
                                            Viral Reshared Media
                                          </option>
                                          <option value="referral" title="Referral">
                                            Online Referral (Unpaid)
                                          </option>
                                          <option disabled value="-- OFFLINE --" title="undefined">
                                            {" "}
                                            -- OFFLINE --
                                          </option>
                                          <option value="db" title="Accounts / Database">
                                            Accounts / Database Marketing
                                          </option>
                                          <option value="past_client" title="Past Client">
                                            Past Client
                                          </option>
                                          <option value="affiliate" title="Affiliate (Paid)">
                                            Affiliate (Paid) / JV Marketing
                                          </option>
                                          <option value="direct_mail" title="Direct Mail">
                                            Direct Mail
                                          </option>
                                          <option value="event" title="Live Event">
                                            Live Events
                                          </option>
                                          <option value="print" title="Print Advertising">
                                            Print Ad
                                          </option>
                                          <option value="referral" title="Referral (Unpaid)">
                                            Offline Referral (Unpaid) / Biz Dev
                                          </option>
                                          <option value="tv" title="TV">
                                            TV
                                          </option>
                                          <option value="radio" title="Radio">
                                            Radio
                                          </option>
                                          <option value="cold_call" title="Phone Cold Call">
                                            Phone Cold Call
                                          </option>
                                          <option disabled value="-- Other --" title="undefined">
                                            {" "}
                                            -- OTHER --
                                          </option>
                                          <option value="imported" title="Imported">
                                            Imported Leads
                                          </option>
                                          <option value="live-transfer" title="Live Transfer">
                                            Live Transfer Leads
                                          </option>
                                          <option value="purchased-data" title="Purchased Data">
                                            Purchased Data
                                          </option>
                                        </select>
                                      </div>
                                      <div className="col-sm-5 utm_source_tooltip campaign_tooltip" />
                                    </div>

                                    <div className="mb-3 row">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            Required. Use <strong>utm_source</strong> to identify a search
                                            engine, newsletter name, or other source. <em>Example</em>:
                                            utm_source=google
                                            <br />
                                            <strong>{"{swift_trafficsource}"}</strong> will automatically
                                            change to match the social media channel if you post to multiple
                                            locations at once.
                                          </Tooltip>
                                        }
                                      >
                                        <label
                                          htmlFor="utm_medium"
                                          className="col-sm-2 col-form-label trafficSourceLabel"
                                        >
                                          Traffic Source
                                        </label>
                                      </OverlayTrigger>
                                      <div className="col-sm-5 utm_medium">
                                        <div className="trafficSourceOpt">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="utm_medium"
                                            name="utm_medium"
                                            placeholder="Facebook.com"
                                            defaultValue="{swift_trafficsource}"
                                          />
                                        </div>
                                        <div className="emailOnlyOption d-none">
                                          <select className="form-select" defaultValue="">
                                            <option>== Emails ==</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-sm-5 utm_medium_tooltip campaign_tooltip">
                                        <p />
                                      </div>
                                    </div>

                                    <div className="mb-3 row press_release_row d-none">
                                      <label htmlFor="utm_medium" className="col-sm-2 col-form-label">
                                        Press Release
                                      </label>
                                      <div className="col-sm-5">
                                        <select className="form-select" defaultValue="">
                                          <option>== Press Release here (doctype) ==</option>
                                        </select>
                                      </div>
                                      <div className="col-sm-5" />
                                    </div>

                                    <div className="mb-3 row group_audience_row d-none">
                                      <label htmlFor="utm_medium" className="col-sm-2 col-form-label">
                                        Group / Audience
                                      </label>
                                      <div className="col-sm-5">
                                        <select className="form-select" defaultValue="">
                                          <option>== Group / Audience here ==</option>
                                        </select>
                                      </div>
                                      <div className="col-sm-5" />
                                    </div>
                                    <div className="mb-3 row direct_mail_row d-none">
                                      <label htmlFor="utm_medium" className="col-sm-2 col-form-label">
                                        Direct Mail
                                      </label>
                                      <div className="col-sm-5">
                                        <select className="form-select" defaultValue="">
                                          <option>== Uploads / PDFs here ==</option>
                                        </select>
                                      </div>
                                      <div className="col-sm-5"></div>
                                    </div>

                                    <div className="mb-3 row email_subject_row d-none">
                                      <label htmlFor="email_subject" className="col-sm-2 col-form-label">
                                        Subject Line
                                      </label>
                                      <div className="col-sm-6">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="email_subject"
                                          name="email_subject"
                                          placeholder=""
                                          defaultValue=""
                                        />
                                      </div>
                                      <div className="col-sm-4"></div>
                                    </div>
                                    <div className="mb-3 row campaignContentImg">
                                      <label className="col-sm-2 col-form-label">
                                        <span className="ContentAdLabel">Content / Creative / Ad</span>
                                      </label>
                                      <div className="col-sm-5">
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>Upload your image. Recommended: 1024x1024</Tooltip>
                                          }
                                        >
                                          <div className="imageUploader">
                                            <a
                                              href="#"
                                              data-bs-toggle="modal"
                                              data-bs-target="#global_image_tool_container"
                                            >
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </a>
                                          </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip>Upload your video</Tooltip>}
                                        >
                                          <div className="videoUploader d-none">
                                            <a
                                              href="#"
                                              data-bs-toggle="modal"
                                              data-bs-target="#global_image_tool_container"
                                            >
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </a>
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                    <div className="mb-3 row ContentCreativeAdRow">
                                      <div className="col-sm-2 col-form-label">
                                        <span className="ContentAdLabel">Content / Creative / Ad</span>

                                        <div className="contactToolContainer d-none pt-3">
                                          <div className="row">
                                            <div className="col-sm-12 mb-3" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-5 campaignContent campaignContentCol">
                                        <div className="AdContentContainer">
                                          <textarea
                                            name="AdContent"
                                            id="AdContent"
                                            className="form-control"
                                            cols="30"
                                            rows="5"
                                          />
                                        </div>
                                        <div className="SEOURLContainer d-none">
                                          <input
                                            type="text"
                                            name="SEOURL"
                                            id="SEOURL"
                                            className="form-control"
                                            defaultValue="https://"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-5 d-none">
                                        <ul className="nav nav-tabs" id="uploadCreateTab" role="tablist">
                                          <li className="nav-item">
                                            <a
                                              className="nav-link active"
                                              id="Upload-tab"
                                              data-toggle="tab"
                                              href="#UploadTab"
                                              role="tab"
                                              aria-controls="home"
                                              aria-selected="true"
                                            >
                                              Upload
                                            </a>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className="nav-link"
                                              id="Create-tab"
                                              data-toggle="tab"
                                              href="#CreateTab"
                                              role="tab"
                                              aria-controls="profile"
                                              aria-selected="false"
                                            >
                                              Create
                                            </a>
                                          </li>
                                        </ul>
                                        <div className="tab-content" id="uploadCreateTabContent">
                                          <div
                                            className="tab-pane fade show active"
                                            id="UploadTab"
                                            role="tabpanel"
                                            aria-labelledby="Upload-tab"
                                          >
                                            <div className="mb-3 text-center">
                                              <div
                                                className="imageUploader"
                                                data-bs-toggle="tooltip"
                                                data-bs-title="Upload your image. Recommended: 1024x1024"
                                              >
                                                <a
                                                  href="#"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#global_image_tool_container"
                                                >
                                                  <i className="bi bi-cloud-arrow-up-fill" />
                                                </a>
                                              </div>
                                            </div>
                                            <div className="mb-3">
                                              <select
                                                className="form-select reviewSelection"
                                                name="reviewSelection"
                                                defaultValue=""
                                              >
                                                <option value="">== Recent Reviews ==</option>
                                                <option value="1">Reviews Show Here</option>
                                              </select>
                                            </div>
                                            <div className="mb-3 reviewPreviewContainer d-none">
                                              Actual Review Text Coming Soon to this space.
                                            </div>
                                          </div>
                                          <div
                                            className="tab-pane fade"
                                            id="CreateTab"
                                            role="tabpanel"
                                            aria-labelledby="Create-tab"
                                          >
                                            Coming soon
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            Used for paid search. Use <strong>utm_term</strong> to note the
                                            keywords for this ad.
                                            <br />
                                            <em>Example</em>: utm_term=running+shoes
                                          </Tooltip>
                                        }
                                      >
                                        <label htmlFor="utm_term" className="col-sm-2 col-form-label">
                                          Campaign Term
                                        </label>
                                      </OverlayTrigger>
                                      <div className="col-sm-5">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="utm_term"
                                          name="utm_term"
                                          placeholder=""
                                          defaultValue="KeyWord"
                                        />
                                      </div>
                                      <div className="col-sm-5 utm_term_tooltip campaign_tooltip" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel-default" id="URLInj_greenContainer">
                <div className="panel-heading">
                  <h3 className="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      href="#URLInj_green"
                      role="button"
                      aria-expanded="true"
                      aria-controls="URLInj_green"
                    >
                      <i className="bi bi-person-fill" />
                      Click Tracking
                    </a>
                  </h3>
                </div>
                <div id="URLInj_green" className="panel-collapse collapse">
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-lg-12 py-3">
                        <div className="mb-0">
                          <input
                            type="checkbox"
                            name="GU_track_individual"
                            id="GU_track_individual"
                            className="css-checkbox compressURLOn"
                            defaultValue="1"
                          />
                          <label htmlFor="GU_track_individual" className="css-checkbox-label radGroup2 mb-0">
                            <i className="bi bi-search" /> Track individual recipient views & actions if
                            possible{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  Be sure to inject "ref={"{ReferrerContactIDNumber}"}" OR "refemail=
                                  {"{referrer's email here}"}" in the URL you send out.
                                </Tooltip>
                              }
                            >
                              <span>
                                <i className="bi bi-question-circle-fill" />
                              </span>
                            </OverlayTrigger>
                          </label>
                        </div>
                        <p className="mb-0">
                          <strong>NOTE:</strong> Be sure your return URL is set to{" "}
                          <a href="https://click.swiftcrm.com/{username}/rr=1">
                            https://click.swiftcrm.com/{"username"}/rr=1
                          </a>
                        </p>
                      </div>
                      <div className="col-lg-12 pb-3">
                        <div className="mb-0">
                          <input
                            type="checkbox"
                            name="GU_notify_you"
                            id="GU_notify_you"
                            className="css-checkbox compressURLOn"
                            defaultValue="1"
                          />
                          <label htmlFor="GU_notify_you" className="css-checkbox-label radGroup2 mb-0">
                            <i className="bi bi-bell-fill" /> Notify
                          </label>
                        </div>
                        <div className="GU_notifyMeShow mt-3 display-none">
                          <div className="row row-cols-lg-auto g-3 align-items-center mb-3">
                            <div className="col-12">
                              <select
                                name="GU_notify_you_by"
                                id="GU_notify_you_by"
                                className="form-select"
                                defaultValue=""
                              >
                                <option value="email_me">Email Me {"PrimaryEmailHere"}</option>
                                <option value="email_to">Email To</option>
                                <option value="email_referrer">Email Referrer (Dynamic) + Me</option>
                                <option value="sms_me">SMS Me</option>
                                <option value="sms_to">SMS To</option>
                              </select>
                            </div>
                            <div className="col-12">
                              <div className="input-group ml-2 GU_notify_you_by_field GU_email_referrer_container display-none">
                                <span
                                  data-bs-toggle="tooltip"
                                  data-bs-title='Be sure to inject "ref={ReferrerContactIDNumber}" OR "refemail={referrer&#39;s email here}" in the URL you send out.'
                                >
                                  <i className="bi bi-question-circle-fill" />
                                </span>
                              </div>

                              <div className="input-group ml-2 GU_notify_you_by_field GU_email_to_container display-none">
                                <div className="input-group-text">
                                  <i className="bi bi-envelope-fill" />
                                </div>
                                <input type="text" name="email_to" id="email_to" className="form-control" />
                              </div>

                              <div className="input-group ml-2 GU_notify_you_by_field GU_sms_to_container display-none">
                                <div className="input-group-text">
                                  <i className="bi bi-phone-fill" />
                                </div>
                                <input type="text" name="sms_to" id="sms_to" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="row row-cols-lg-auto g-3 align-items-center">
                            <div className="col-12">When</div>
                            <div className="col-12">
                              <select name="GU_when_to_notify" id="GU_when_to_notify" className="form-select">
                                <option value="Goal Return Page Reached">Goal Return Page Reached</option>
                                <option value="Clicked">Clicked</option>
                                <option value="Not Clicked">Not Clicked</option>
                              </select>
                            </div>
                            <div className="col-12">
                              <span
                                data-bs-toggle="tooltip"
                                data-bs-title="If we know contact info for this person or people, we'll let you know when they click your link."
                              >
                                <i className="bi bi-question-circle-fill" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel-default" id="URLInj_blueContainer">
                <div className="panel-heading">
                  <h3 className="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      href="#URLInj_blue"
                      role="button"
                      aria-expanded="true"
                      aria-controls="URLInj_blue"
                    >
                      <i className="bi bi-gear-fill" />
                      Automation
                    </a>
                  </h3>
                </div>
                <div id="URLInj_blue" className="panel-collapse collapse">
                  <div className="panel-body pt-3">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="dataInner">
                          <div className="mb-3 row initialData g-0">
                            <div className="col-sm-12">
                              <div className="mb-0">
                                <input
                                  type="checkbox"
                                  name="adjustContactTagScore"
                                  id="adjustContactTagScore"
                                  className="css-checkbox compressURLOn"
                                  defaultValue="1"
                                  defaultChecked="checked"
                                />
                                <label
                                  htmlFor="adjustContactTagScore"
                                  className="css-checkbox-label radGroup2 mb-0"
                                >
                                  <i className="bi bi-reception-4" /> Adjust Contact's...{" "}
                                  <a href="https://swiftcrm.com/support/tagscore" target="_blank">
                                    <i className="bi bi-question-circle-fill" />
                                  </a>
                                </label>
                              </div>
                              <div className="mt-3 adjustTagScoreContainer">
                                <div className="row row-cols-lg-auto g-2 align-items-center mb-3">
                                  <div className="col-12">
                                    RAVE Score &nbsp;{" "}
                                    <a href="https://SwiftCRM.com/support/rave-score" target="_blank">
                                      <i className="bi bi-question-circle-fill" />
                                    </a>
                                  </div>
                                  <div className="col-12">
                                    <select
                                      name="adjust_tag_by"
                                      id="adjust_tag_by"
                                      className="form-select"
                                      defaultValue=""
                                    >
                                      <option value="by">by</option>
                                      <option value="to">to</option>
                                    </select>
                                  </div>
                                  <div className="col-12">
                                    <select
                                      name="adjust_tag_plus_minus"
                                      id="adjust_tag_plus_minus"
                                      className="form-select"
                                      defaultValue=""
                                    >
                                      <option value="+">+</option>
                                      <option value="-">-</option>
                                    </select>
                                  </div>
                                  <div className="col-12">
                                    <input
                                      type="text"
                                      name="adjust_tag_score"
                                      id="adjust_tag_score"
                                      className="form-control contactTagScore"
                                      defaultValue="10"
                                    />
                                  </div>
                                </div>
                                <div className="row row-cols-lg-auto g-2 align-items-center mb-3">
                                  <div className="col-12">Adjust Tag</div>
                                  <div className="col-12">
                                    <input
                                      type="text"
                                      name="adjust_tag2"
                                      id="adjust_tag2"
                                      className="form-control ml-2"
                                    />
                                  </div>
                                  <div className="col-12">
                                    <select
                                      name="adjust_tag_by2"
                                      id="adjust_tag_by2"
                                      className="form-select"
                                      defaultValue=""
                                    >
                                      <option value="by">by</option>
                                      <option value="to">to</option>
                                    </select>
                                  </div>
                                  <div className="col-12">
                                    <select
                                      name="adjust_tag_plus_minus2"
                                      id="adjust_tag_plus_minus2"
                                      className="form-select"
                                      defaultValue=""
                                    >
                                      <option value="+">+</option>
                                      <option value="-">-</option>
                                    </select>
                                  </div>
                                  <div className="col-12">
                                    <input
                                      type="text"
                                      name="adjust_tag_score2"
                                      id="adjust_tag_score2"
                                      className="form-control contactTagScore"
                                      defaultValue="10"
                                    />
                                  </div>
                                </div>
                                <div className="row row-cols-lg-auto g-2 align-items-center">
                                  <div className="col-12">Adjust Tag</div>
                                  <div className="col-12">
                                    <input
                                      type="text"
                                      name="adjust_tag3"
                                      id="adjust_tag3"
                                      className="form-control ml-2"
                                    />
                                  </div>
                                  <div className="col-12">
                                    <select
                                      name="adjust_tag_by3"
                                      id="adjust_tag_by3"
                                      className="form-select"
                                      defaultValue=""
                                    >
                                      <option value="by">by</option>
                                      <option value="to">to</option>
                                    </select>
                                  </div>
                                  <div className="col-12">
                                    <select
                                      name="adjust_tag_plus_minus3"
                                      id="adjust_tag_plus_minus3"
                                      className="form-select"
                                      defaultValue=""
                                    >
                                      <option value="+">+</option>
                                      <option value="-">-</option>
                                    </select>
                                  </div>
                                  <div className="col-12">
                                    <input
                                      type="text"
                                      name="adjust_tag_score3"
                                      id="adjust_tag_score3"
                                      className="form-control contactTagScore"
                                      defaultValue="10"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row row-cols-lg-auto g-0 align-items-center mb-3 initialData">
                            <div className="col-12">
                              <input
                                type="checkbox"
                                name="visitor_funnel_status"
                                id="visitorFunnelStatusCheckbox"
                                className="css-checkbox compressURLOn"
                                defaultValue="move"
                              />
                              <label
                                htmlFor="visitorFunnelStatusCheckbox"
                                className="css-checkbox-label radGroup2"
                              >
                                Set Visitor Funnel Status / Agenda to{" "}
                              </label>
                            </div>
                            <div className="col-12">
                              <select
                                className="form-select ms-2"
                                name="visitor_funnel_status_options"
                                id="visitorFunnelStatusOptions"
                                defaultValue=""
                              >
                                <option value="capture">Capture</option>
                                <option value="build_trust">Build Trust</option>
                                <option value="tripwire_offer">Tripwire Offer</option>
                                <option value="intro_offer">Intro Offer</option>
                                <option value="main_offer">Main Offer</option>
                                <option value="upsell_offer">Upsell Offer</option>
                                <option value="promoter">Promoter</option>
                              </select>
                            </div>
                          </div>
                          <div className="mb-3 row initialData extraForYouOpt g-0">
                            <div className="col-sm-12 googlTag">
                              <div className="mb-0">
                                <input
                                  type="checkbox"
                                  name="tagOpt"
                                  id="tagOpt"
                                  className="css-checkbox compressURLOn"
                                  defaultValue="tagContent"
                                />
                                <label htmlFor="tagOpt" className="css-checkbox-label radGroup2 mb-0">
                                  <i className="bi bi-tag-fill" /> Fire Google Tag (i.e. Retargeting Pixel){" "}
                                </label>
                              </div>
                              <div className="tagOptShow display-none mt-3">
                                <div className="row">
                                  <div className="col-sm-6 mb-sm-0 mb-3">
                                    <label>Tag Name</label>
                                    <input type="text" name="tagName" id="tagName" className="form-control" />
                                  </div>
                                  <div className="col-sm-6 mb-sm-0 mb-3">
                                    <label>Value (Optional)</label>
                                    <input
                                      type="text"
                                      name="tagValue"
                                      id="tagValue"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-3 row initialData extraForYouOpt g-0">
                            <div className="col-sm-12 additionalVars">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="mb-0">
                                    <input
                                      type="checkbox"
                                      name="additional_var_opt"
                                      id="additionalVarOpt"
                                      className="css-checkbox compressURLOn"
                                      defaultValue="additionalVar"
                                    />
                                    <label
                                      htmlFor="additionalVarOpt"
                                      className="css-checkbox-label radGroup2 mb-0"
                                    >
                                      <i className="bi bi-person-fill" /> Additional Variables
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="row addAdditionalVarsContainer pt-3 display-none">
                                <div className="col-lg-12 used_variables_container">
                                  <div className="row row-cols-lg-auto g-2 align-items-center additionalVarsRow">
                                    <div className="col-12">
                                      <input
                                        type="checkbox"
                                        name="user_variables_active_0"
                                        className="css-checkbox"
                                        id="var1"
                                      />
                                      <label
                                        htmlFor="var1"
                                        className="css-checkbox-label radGroup2 mb-0 h28"
                                      ></label>
                                    </div>
                                    <div className="col-12">
                                      <input
                                        name="user_variables_name_0"
                                        type="text"
                                        className="form-control variable_name"
                                        placeholder="Variable Name"
                                      />
                                    </div>
                                    <div className="col-12">=</div>
                                    <div className="col-12">
                                      <input
                                        name="user_variables_value_0"
                                        type="text"
                                        className="form-control ml-sm-2 variable_value"
                                        placeholder="Variable Value"
                                      />
                                    </div>
                                    <div className="col-12">
                                      <a
                                        href="#"
                                        className="btn-edit"
                                        data-bs-toggle="tooltip"
                                        data-bs-title="Edit"
                                      >
                                        <i className="bi bi-pencil-fill" />
                                      </a>
                                      <a
                                        href="#"
                                        className="btn-delete"
                                        data-bs-toggle="tooltip"
                                        data-bs-title="Delete"
                                      >
                                        <i className="bi bi-trash-fill" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="row">
                                    <div className="col-lg-12 text-left mt-2">
                                      <button
                                        type="button"
                                        className="btnAddNewGreenDashed"
                                        id="addNewCampaignVar"
                                        data-bs-toggle="tooltip"
                                        data-bs-title="Add New"
                                      >
                                        <i className="bi bi-plus-lg" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 d-none user_variables_management">
                                  <div className="table-responsive">
                                    <table
                                      id="userVariables_table"
                                      className="table table-striped customCheckbox table-head-color dataTable no-footer"
                                      cellSpacing="0"
                                      width="100%"
                                    >
                                      <thead>
                                        <tr>
                                          <th className="text-center">#</th>
                                          <th>Used For…</th>
                                          <th>Variable Name</th>
                                          <th>Variable Value</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className="utm_campaign_addnew">
                                          <td colSpan="5" className="text-center">
                                            <button
                                              type="button"
                                              className="btnAddNew btnAddNewVar"
                                              data-bs-toggle="tooltip"
                                              data-bs-title="Add New"
                                            ></button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-3 row initialData extraForYouOpt g-0">
                            <div className="col-sm-12">
                              <div className="mb-0">
                                <input
                                  type="checkbox"
                                  name="urlVerStickyOpt"
                                  id="urlVerStickyOpt"
                                  className="css-checkbox compressURLOn"
                                  defaultValue="urlVerSticky"
                                />
                                <label
                                  htmlFor="urlVerStickyOpt"
                                  className="css-checkbox-label radGroup2 mb-0"
                                >
                                  <i className="bi bi-puzzle-fill" /> Make URL Variables Sticky{" "}
                                  <span
                                    data-bs-toggle="tooltip"
                                    data-bs-title="If you need variables tracked through multiple domains (such as a user leaving your site to pay then returning), this can solve that."
                                  >
                                    <i className="bi bi-question-circle-fill" />
                                  </span>
                                </label>
                              </div>
                              <div className="urlVerStickyOptShow display-none">
                                <div className="row">
                                  <div className="col-sm-12 mt-3">
                                    <p>
                                      <strong>NOTE:</strong> Be sure your return URL is set to{" "}
                                      <a href="https://click.swiftcrm.com/{username}/rr=1">
                                        https://click.swiftcrm.com/{"username"}/rr=1
                                      </a>
                                    </p>
                                  </div>
                                  <div className="col-sm-12 mb-3">
                                    <label>
                                      URL we should forward this returning traffic to after reattaching
                                      tracking variables{" "}
                                      <span
                                        data-bs-toggle="tooltip"
                                        data-bs-title="This could be another EZLink if needed"
                                      >
                                        <i className="bi bi-question-circle-fill" />
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      name="returningUrl"
                                      id="returningUrl"
                                      defaultValue="https://"
                                      className="form-control"
                                      placeholder="https://"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel-default" id="URLInj_grayContainer">
                <div className="panel-heading">
                  <h3 className="panel-title">
                    <a
                      data-bs-toggle="collapse"
                      href="#URLInj_gray"
                      role="button"
                      aria-expanded="true"
                      aria-controls="URLInj_gray"
                    >
                      <i className="bi bi-three-dots" />
                      More Options
                    </a>
                  </h3>
                </div>
                <div id="URLInj_gray" className="panel-collapse collapse">
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-lg-12 py-3">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-lg-12 mb-3">
                                <div className="colHeading">
                                  <strong>
                                    <i className="bi bi-tags-fill" /> Tags
                                  </strong>
                                </div>
                                <div className="dh-block-body">
                                  <input
                                    type="text"
                                    name="url_tags"
                                    id="url_tags"
                                    className="form-control"
                                    defaultValue="#tag"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-12 mb-3">
                                <div className="colHeading">
                                  <strong>
                                    <i className="bi bi-box-arrow-right" /> Redirect
                                  </strong>
                                </div>
                                <div className="dh-block-body">
                                  <select className="form-select" defaultValue="">
                                    <option>302 Temporary</option>
                                    <option>301 Permanent</option>
                                    <option>307 Temporary</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-lg-12 mb-3">
                                <div className="colHeading">
                                  <strong>
                                    <i className="bi bi-snow" /> Forward Parameters
                                  </strong>
                                </div>
                                <div className="dh-block-body">
                                  <div className="col-sm-12">
                                    <input
                                      type="checkbox"
                                      name="forwardParameters"
                                      id="forwardParameters"
                                      className="css-checkbox"
                                      defaultValue="1"
                                    />
                                    <label
                                      htmlFor="forwardParameters"
                                      className="css-checkbox-label radGroup2 mb-0"
                                    >
                                      Forward Parameters{" "}
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Pass through any URL variables</Tooltip>}
                                      >
                                        <span>
                                          <i className="bi bi-question-circle-fill" />
                                        </span>
                                      </OverlayTrigger>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-lg-12 mb-3">
                                <div className="colHeading">
                                  <strong>
                                    <i className="bi bi-file-image" /> Featured Image
                                  </strong>
                                </div>
                                <div className="dh-block-body">
                                  <div className="folderIcon">
                                    <StaticImage src="../../../images/avatar.gif" alt="" />
                                  </div>
                                  <button type="button" className="btn btn-default btnDocFormIconEdit">
                                    <span data-bs-toggle="tooltip" data-bs-title="Edit folder image">
                                      <i className="bi bi-pencil-fill" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="colHeading">
                                  <strong>
                                    <i className="bi bi-globe" /> SEO{" "}
                                  </strong>
                                </div>
                                <div className="dh-block-body">
                                  <div className="row row-cols-lg-auto g-3 align-items-center mb-3">
                                    <div className="col-12">No Follow</div>
                                    <div className="col-12">
                                      <div className="toggleSwitch">
                                        <input
                                          type="checkbox"
                                          defaultValue="1"
                                          name="nofollow"
                                          id="nofollow"
                                        />
                                        <label />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row row-cols-lg-auto g-3 align-items-center">
                                    <div className="col-12">No Index</div>
                                    <div className="col-12">
                                      <div className="toggleSwitch">
                                        <input type="checkbox" defaultValue="1" name="noindex" id="noindex" />
                                        <label />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shortenURLContainer display-none">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <p className="py-3 mb-0">
                    <span className="globalLongURL">{"SUPERLONGURLHERE"}</span>
                    <a
                      className="btnSuperLongURLAction"
                      href="#"
                      id="btnGlobalURLSuperLongURLPreview"
                      target="_blank"
                      data-bs-toggle="tooltip"
                      data-bs-title="Preview This URL"
                    >
                      <i className="bi bi-box-arrow-up-right" />
                    </a>
                    <a
                      className="btnSuperLongURLAction"
                      href="#"
                      id="btnGlobalURLSuperLongURLCopyLink"
                      data-clipboard-target="#GlobalURLCompressedURLCopyLink"
                      data-bs-toggle="tooltip"
                      data-bs-title="Copy This URL"
                    >
                      <i className="bi bi-files" />
                    </a>
                    <input id="GlobalURLSuperLongURLCopyLink" defaultValue="#" />
                  </p>
                </div>

                <div className="col-lg-12 shortenURLActionContainer">
                  <p className="text-center">is shortened / customized to</p>

                  <div className="compressed_url_viewer pb-3">
                    <div className="input-group mt-3 compressed_url_editor justify-content-center">
                      <div className="input-group-text input-group-text-https">https://</div>
                      <select className="form-control publicBaseURLSelection">
                        <option value="click.swiftcrm.com/username">click.swiftcrm.com/{"username"}</option>
                        <option value="newMappingURL">Add New Mapped URL</option>
                      </select>
                      <span className="input-group-text input-group-text-dash">/</span>
                      <input
                        type="text"
                        className="form-control GlobalURLHashInput"
                        defaultValue="AutoGenButAllowInputOverride"
                      />
                    </div>
                    <div className="text-center mt-1 compressed_url_viewer">
                      <a
                        href="https://click.swiftcrm.com/{username}/AutoGenButAllowInputOverride"
                        target="_blank"
                        className="text-dash-underline btnPreviewLinkYourWebsite"
                      >
                        click.swiftcrm.com/{"username"}/
                        <span className="GlobalURLHash">AutoGenButAllowInputOverride</span>
                      </a>
                      <a
                        href="https://click.swiftcrm.com/{username}/AutoGenButAllowInputOverride"
                        id="btnGlobalURLCompressedURLPreview"
                        target="_blank"
                        data-bs-toggle="tooltip"
                        data-bs-title="Preview This URL"
                      >
                        <i className="bi bi-box-arrow-up-right" />
                      </a>
                      <a
                        href="#"
                        id="btnGlobalURLCompressedURLCopyLink"
                        data-clipboard-target="#GlobalURLCompressedURLCopyLink"
                        data-bs-toggle="tooltip"
                        data-bs-title="Copy This URL"
                      >
                        <i className="bi bi-files" />
                      </a>
                      <input
                        id="GlobalURLCompressedURLCopyLink"
                        defaultValue="https://click.swiftcrm.com/{username}/AutoGenButAllowInputOverride"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 text-center shareContainer">
                  <div className="row align-items-center">
                    <div className="col-lg-12 shareAction">
                      <ul>
                        <li className="black-bg">
                          <a
                            href="#"
                            className="btnGlobalURLQRCode"
                            data-bs-toggle="tooltip"
                            data-bs-title="Scan QR Code"
                          >
                            <i className="bi bi-qr-code" />
                          </a>
                        </li>
                        <li className="paper-plane-bg">
                          <a
                            data-bs-toggle="tooltip"
                            data-bs-title="Email This Page"
                            href="mailto:?subject=You would like this&amp;body=Hello%0A%0AYou%20will%20like%20this%3A%0Ahttps://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride"
                            target="_blank"
                          >
                            <i className="bi bi-send-fill" />
                          </a>
                        </li>
                        <li className="fb-bg">
                          <a
                            data-bs-toggle="tooltip"
                            data-bs-title="Share On Facebook"
                            href="https://www.facebook.com/sharer/sharer.php?u=https://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride"
                            target="_blank"
                          >
                            <i className="bi bi-facebook" />
                          </a>
                        </li>
                        <li className="twitter-bg">
                          <a
                            data-bs-toggle="tooltip"
                            data-bs-title="Tweet This Page"
                            href="https://twitter.com/share?url=https://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride&amp;text=Hello%0A%0AYou%20will%20like%20this%3A%0A"
                            target="_blank"
                          >
                            <i className="bi bi-twitter" />
                          </a>
                        </li>
                        <li className="pinterest-bg">
                          <a
                            data-bs-toggle="tooltip"
                            data-bs-title="Share This Page on Pinterest"
                            href="https://www.pinterest.com/pin/create/button/?url=https://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride&amp;media=https://swiftcrm.com/images/user_/"
                            target="_blank"
                          >
                            <i className="bi bi-pinterest" />
                          </a>
                        </li>
                        <li className="linkedin-bg">
                          <a
                            data-bs-toggle="tooltip"
                            data-bs-title="Share This Page on LinkedIn"
                            href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://click.SwiftCRM.com/{username}/AutoGenButAllowInputOverride&amp;title=You%20will%20like%20this&amp;summary=Hello%0A%0AYou%20will%20like%20this%3A%0A%0A"
                            target="_blank"
                          >
                            <i className="bi bi-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row g-0 rowQRCode pt-3 display-none">
                    <div className="col-lg-12 colHeading">
                      <strong>
                        <i className="bi bi-qr-code" /> QR Code
                      </strong>
                    </div>
                    <div className="col-lg-12 dh-block-body">
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <div id="shareurl_qrcode" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="container p-0 border-0">
            <div className="row align-items-center">
              <div className="col-lg-12 text-end">
                <button type="button" className="btn btn-primary btnAddURLInjector">
                  <i className="bi bi-check2" /> Save
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default AddUrlModal
