import * as React from "react"

import PrivateRoute from "../components/privateRoute"
import ReduxWrapper from "../redux/reduxWrapper"
import UrlsList from "../components/Urls/Listing/UrlsList"
import Seo from "../components/seo"

const UrlsPage = props => {
  return (
    <>
      <Seo title="URLs" />
      <PrivateRoute component={UrlsList} location={props.location} />
    </>
  )
}

const WrappedPage = props => <ReduxWrapper element={<UrlsPage {...props} />} />
export default WrappedPage
