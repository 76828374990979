import * as React from "react"

const UrlsListFilterAndSearch = () => {
  return (
    <div className="input-group filterDropdown mb-1">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="bi bi-bullseye" />
      </button>
      <ul className="dropdown-menu">
        <li>
          <span className="dropdown-item-text">== Saved Filtered Views ==</span>
        </li>
        <li>
          <a className="dropdown-item createNewFilterView" href="#">
            <i className="bi bi-plus" /> Create New Filtered View
          </a>
        </li>
      </ul>
      <input type="search" className="form-control tableSearch" placeholder="Search" />
    </div>
  )
}

export default UrlsListFilterAndSearch
